import React from 'react'
import { trackEvent } from '../helpers/tracking'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import ImageGallery from 'react-image-gallery'

import one from '../images/featured/front-page-01.jpg'
import two from '../images/featured/front-page-02.jpg'
import three from '../images/featured/front-page-03.jpg'
import four from '../images/featured/front-page-04.jpg'
import five from '../images/featured/front-page-05.jpg'
import six from '../images/featured/front-page-06.jpg'
import seven from '../images/featured/front-page-07.jpg'
import eight from '../images/featured/front-page-08.jpg'
import ten from '../images/featured/front-page-10.jpg'
import eleven from '../images/featured/front-page-11.jpg'

import 'react-image-gallery/styles/css/image-gallery.css'

const images = [
  {
    original: one,
    thumbnail: one,
  },
  {
    original: two,
    thumbnail: two,
  },
  {
    original: three,
    thumbnail: three,
  },
  {
    original: four,
    thumbnail: four,
  },
  {
    original: five,
    thumbnail: five,
  },
  {
    original: six,
    thumbnail: six,
  },
  {
    original: seven,
    thumbnail: seven,
  },
  {
    original: eight,
    thumbnail: eight,
  },
  {
    original: ten,
    thumbnail: ten,
  },
  {
    original: eleven,
    thumbnail: eleven,
  },
]

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements array[i] and array[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = array[i]; array[i] = array[j]; array[j] = t
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[]} />
    <Grid container spacing={24}>
      <Grid item xs={12} sm={6} md={7}>
        <Typography variant="subtitle1">
          Spiral Classics is the <strong>reliable</strong> source for 
          vintage classical LPs. I have an extensive stock of classical
          music vinyl records, all{' '}
          <Link to="/grading-cleaning">carefully graded</Link> for condition.
        </Typography>
        <Button
          component={Link}
          to="/stock-list"
          variant="contained"
          color="secondary"
          size="large"
          style={{ margin: '20px 0 25px' }}
          onClick={() =>
            trackEvent({ category: 'Home', action: 'View stock list' })
          }
        >
          View stock list
        </Button>
        <Typography variant="subtitle1">
          New to collecting, or unsure what to look for? Start with my{' '}
          <Link to="/guide-to-collecting">Guide to collecting</Link>.
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography variant="subtitle2">I can offer you:</Typography>
        <br />
        <Typography variant="subtitle2">
          <ul>
            <li>
              Audiophile records, including Decca SXL, Columbia SAX, ASD, RCA
              Living Stereo, Mercury
            </li>
            <li>
              Collectable records, including large selection of mono &amp; early
              stereo originals
            </li>
            <li>Rare classical music recordings from the 1950s to the 1980s</li>
          </ul>
        </Typography>
        <Typography variant="subtitle2">
          Full range of repertoire includes:
        </Typography>
        <br />
        <Typography variant="subtitle2">
          <ul>
            <li>Great conductors, violinists, cellists, pianists</li>
            <li>Opera sets, singers</li>
            <li>Early music, Baroque music </li>
            <li>Twentieth-century composers</li>
            <li>British composers, including Lyrita</li>
          </ul>
        </Typography>
        {/* <p>
          Join the mailing list to be notified of updates to the catalog and
          stock lists, as well as special offers.
        </p> */}
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <ImageGallery
          items={shuffle(images)}
          showFullscreenButton={false}
          slideDuration={800}
          slideInterval={5000}
          autoPlay
          onPlay={() =>
            trackEvent({ category: 'Home', action: 'Carousel - play' })
          }
          onPause={() =>
            trackEvent({ category: 'Home', action: 'Carousel - pause' })
          }
          onSlide={() =>
            trackEvent({ category: 'Home', action: 'Carousel - scroll' })
          }
          onThumbnailClick={(e, i) =>
            trackEvent({
              category: 'Home',
              action: 'Carousel - view image',
              label: images[i].original,
            })
          }
        />
      </Grid>
    </Grid>
  </Layout>
)

export default IndexPage
